<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <div class="name">
        <h3>Unos novog člana</h3>
      </div>
      <v-divider></v-divider>

      <v-container fluid class="member-data-form">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" lg="5" class="form-column">
            <v-card class="pa-2" outlined tile>
              <v-form ref="form" v-model="valid">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <h3 class="subsection">Osobni podaci</h3>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Ime *</b></td>
                        <td>
                          <v-text-field
                            required
                            v-model="member.FirstName"
                            :rules="nonEmptyRule"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Prezime *</b></td>
                        <td>
                          <v-text-field
                            required
                            v-model="member.LastName"
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Datum rođenja *</b></td>
                        <td>
                          <v-menu
                            v-model="birthdateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="member.Birthdate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                required
                                :rules="nonEmptyRule"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="member.Birthdate"
                              :max="maxCalendarDate"
                              min="1900-01-01"
                              locale="hr-hr"
                              @input="birthdateMenu = false"
                            ></v-date-picker>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Spol *</b></td>
                        <td>
                          <v-radio-group
                            v-model="member.Salutation"
                            row
                            required
                            :rules="nonEmptyRule"
                          >
                            <v-radio label="Muški" value="Male"></v-radio>
                            <v-radio label="Ženski" value="Female"></v-radio>
                          </v-radio-group>
                        </td>
                      </tr>
                      <tr>
                        <td><b>OIB</b></td>
                        <td>
                          <v-text-field required v-model="member.Tax_Number__c">
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Broj dokumenta</b></td>
                        <td>
                          <v-text-field required v-model="member.ID_Number__c">
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Baza članstva *</b></td>
                        <td>
                          <v-select
                            required
                            :items="dphs"
                            item-text="Name"
                            item-value="Id"
                            v-model="member.Primary_Affiliate__c"
                          ></v-select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3 class="subsection lower">Adresa</h3>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Država *</b></td>
                        <td>
                          <v-select
                            :items="countries"
                            item-text="name"
                            item-value="name"
                            v-model="member.MailingCountry"
                            required
                            :rules="nonEmptyRule"
                          ></v-select>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Ulica i kućni broj *</b></td>
                        <td>
                          <v-text-field
                            v-model="member.MailingStreet"
                            required
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Poštanski broj *</b></td>
                        <td>
                          <v-text-field
                            v-model="member.MailingPostalCode"
                            required
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Grad *</b></td>
                        <td>
                          <v-text-field
                            v-model="member.MailingCity"
                            required
                            :rules="nonEmptyRule"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-form>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="5" class="form-column">
            <v-card class="pa-2" outlined tile>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <h3 class="subsection">Kontakt</h3>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><b>Telefon</b></td>
                      <td>
                        <v-text-field
                          required
                          v-model="member.MobilePhone"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Email</b></td>
                      <td>
                        <v-text-field
                          required
                          v-model="member.npe01__HomeEmail__c"
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td><b>Ne kontaktiraj</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.npsp__Do_Not_Contact__c"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne šalji poštu</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.Mail_Opt_Out__c"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne šalji email</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.HasOptedOutOfEmail"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne šalji SMS</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.SMS_Opt_Out__c"
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Ne zovi</b></td>
                      <td>
                        <v-checkbox v-model="member.DoNotCall"></v-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h3 class="subsection lower">Dodatno</h3>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><b>Bilo srce</b></td>
                      <td>
                        <v-checkbox v-model="member.Bilo_Srce__c"></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Preminuo</b></td>
                      <td>
                        <v-checkbox
                          v-model="member.npsp__Deceased__c"
                        ></v-checkbox>
                      </td>
                    </tr>

                    <tr></tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <div class="save-actions">
              <v-btn
                v-if="!loading"
                @click="saveMember"
                depressed
                color="primary"
                :disabled="!valid"
              >
                Spremi novog člana
              </v-btn>

              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="#1358a3"
                ></v-progress-circular>
              </div>

              <v-alert outlined text type="error" v-show="error">
                {{ error }}
              </v-alert>

              <v-alert outlined text type="success" v-show="success">
                Novi član uspješno dodan!
              </v-alert>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";
import { countries } from "../helpers/countries";
import validateMemberForm from "../helpers/validateMemberForm";

export default {
  data() {
    return {
      member: {
        Primary_Affiliate__c: this.$store.state.account.user.sf_data.Id,
      },
      loading: false,
      error: false,
      success: false,
      activePicker: null,
      birthdateMenu: false,
      countries: countries,
      valid: false,
      nonEmptyRule: [(v) => !!v || "Obavezno polje"],
    };
  },

  async created() {
    this.$store.dispatch("fetchDphs");
  },

  computed: {
    dphs() {
      return this.$store.state.allDphs;
    },

    maxCalendarDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },

  methods: {
    async saveMember(e) {
      e.preventDefault();
      this.resetFields();

      if (this.validateForm()) {
        this.loading = true;

        try {
          const newMemberId = await apiRequest(
            "post",
            `members/new`,
            this.member
          );
          this.success = true;
          this.$router.push({ name: "member", params: { id: newMemberId } });
        } catch (error) {
          this.error = error.response.data.message;
        } finally {
          this.loading = false;
        }
      }
    },

    validateForm() {
      this.$refs.form.validate();
      const { validated, error } = validateMemberForm(this.member);

      if (!validated || !this.valid) {
        this.error = error ? error : false;
        return false;
      }

      return true;
    },

    resetFields() {
      this.loading = false;
      this.error = false;
      this.success = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1264px) {
  .form-column:nth-child(1) {
    margin-right: 50px;
  }
}

.pa-2 {
  border: none;
}

.name {
  h3 {
    display: inline;
  }
}

.member-data-form {
  table tr:hover {
    background: none !important;
  }
  table tr td {
    min-width: 150px;
    border-bottom: 0px !important;
  }
}

.subsection {
  color: #1358a3;

  &.lower {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.buttons-holder {
  button {
    margin-bottom: 10px;
  }
}

.save-actions {
  float: right;
  margin-top: 40px;

  button {
    margin-bottom: 20px;
  }
}
</style>
